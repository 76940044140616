
<template>


  <div   class="main-layout" v-if="resultData">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <div class="mine">
          <img class="top-img" src="../../assets/mine/icon_mine_top.png" alt="">

          <p class="mine-title" >我的</p>

          <div class="otherInfo">

            <div   class="user">
              <div class="userInfo">
                <van-image :src="user ?  user.avatar : ''" radius="50%" class="avatar"></van-image>
                <div  class="center-info">
                  <p class="name">{{(user && user.phone) ? user.phone : '昵称文字文字文字'}}</p>
                  <p v-if="user" class="time">{{user.registrationTime.substr(0,10)}}入驻</p>
                </div>

                <!--            <van-image :src="require('../../assets/mine/icon_setting.png')"  class="setting"></van-image>-->


              </div>
              <div class="block-info" v-if="user">
                <p>区块链地址：{{user.hashCode}}</p>
                <van-image  @click="copyAddress(user.hashCode)" class="block-img"
                           :src="require('../../assets/copy.png')" width="25px" fit="fill"></van-image>
<!--                <p v-else style="font-size: 8px;">区块链地址：18b5KFjmioxSYPVRLnxSY</p>-->
              </div>
            </div>


            <div class="grid-tabs">

              <div class="tab-item" @click="clickToDigitalListPage">
                <div class="tab-item-left">
                  <img class="left-icon" src="@/assets/mine/icon_mine_digital.png" alt="">
                  <p>数字徽章</p>
                </div>
                <div class="tab-item-right">
                  <span>{{resultData.wpBadgeNum}}</span>
                  <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
                </div>

              </div>


              <div class="tab-item" @click="toMyDigitals">
                <div class="tab-item-left">
                  <img class="left-icon" src="@/assets/mine/icon_mine_calture.png" alt="">
                  <p>数字文创</p>
                </div>
                <div class="tab-item-right">
                  <span>{{resultData.wpLiteraryNum}}</span>
                  <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
                </div>

              </div>


              <div class="tab-item" @click="toOrder">
                <div class="tab-item-left">
                  <img class="left-icon" src="@/assets/mine/icon_mine_order.png" alt="">
                  <p>订单</p>
                </div>
                <div class="tab-item-right">
<!--                  <span>{{resultData.orderNum}}</span>-->
                  <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
                </div>

              </div>

              <div class="tab-item" @click="toMyCoupons">
                <div class="tab-item-left">
                  <img class="left-icon" src="@/assets/mine/icon_mine_coupon.png" alt="">
                  <p>券包</p>
                </div>
                <div class="tab-item-right">
                  <span>{{resultData.couponCounts}}</span>
                  <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
                </div>

              </div>

              <div class="tab-item" @click="toMyMap">
                <div class="tab-item-left">
                  <img class="left-icon" src="@/assets/mine/icon_mine_map.png" alt="">
                  <p>地图</p>
                </div>
                <div class="tab-item-right">
                  <span></span>
                  <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
                </div>

              </div>

              <div class="tab-item" @click="toMyLikes">
                <div class="tab-item-left">
                  <img class="left-icon" src="@/assets/mine/icon_mine_like.png" alt="">
                  <p>喜欢</p>
                </div>
                <div class="tab-item-right">
                  <span>{{resultData.likeNum}}</span>
                  <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
                </div>

              </div>

            </div>


            <div class="tabs-row" @click="aboutUs">
              <div class="tab-item-left">
                <p style="font-weight: normal">关于星城玩家</p>
              </div>

              <div class="tab-item-right">
                <img class="share-image"  src="@/assets/mine/icon_image_share.png" alt="">
                <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
              </div>

            </div>


            <div class="tabs-row" @click="toMyshare">
              <div class="tab-item-left">
                <img class="left-icon" src="@/assets/mine/icon_mine_share.png" alt="">
                <p>分享</p>
              </div>
              <div class="tab-item-right">
                <img class="right-icon" src="@/assets/mine/icon_roght_arrow.png" alt="">
              </div>
            </div>



<!--            <div class="card-info">-->
<!--              <div class="card-top" @click="toMyCoupons">-->
<!--                <p class="card-title">我的卡券</p>-->
<!--                <p>可用<span style="color:#FB4D09">{{couponList.length}}</span>张/共{{couponCounts}}张</p>-->
<!--                <img src="../../assets/common/icon_right_arrow.png" alt="">-->
<!--              </div>-->

<!--              <div class="couponsList" v-if="couponList.length > 0">-->
<!--                <img v-show="couponList.length > 1" @click="couponPrev"  class="icon-swipe-left" src="../../assets/common/icon_swipe_left.png" alt="">-->
<!--                <img v-show="couponList.length > 1" @click="couponNext" class="icon-swipe-right" src="../../assets/common/icon_swipe_right.png" alt="">-->
<!--                <van-swipe   class="swiper-carousel"-->
<!--                             ref="swipeRef"-->
<!--                             :loop = "false"-->
<!--                             @change="onChange"-->
<!--                             :show-indicators="false"-->
<!--                             :stop-propagation="false"-->
<!--                >-->

<!--                  <van-swipe-item class="card-body" v-for="(item, index) in couponList" :key="index"   >-->
<!--                    <p class="card-body-title">{{item.title}}</p>-->
<!--                    <p class="card-body-date">有效期: {{item.dateStr}}</p>-->
<!--                    <div :id="`qrcode${index}`" style="padding: 10px" class="qrcode" @click="toBigCode(item)">-->

<!--                    </div>-->
<!--                    <p class="card-code">{{item.code}}</p>-->

<!--                    <div class="cardCode-desc">-->
<!--                      <span>图书券使用细则</span>-->
<!--                      <img  src="../../assets/mine/icon_coupon_tip.png" @click="clickShowCoupon(index)" alt="">-->
<!--                    </div>-->


<!--                  </van-swipe-item>-->



<!--                </van-swipe>-->
<!--              </div>-->
<!--              <div class="no-coupon" v-else>-->
<!--                <img src="../../assets/nodata/icon_no_coupon.png" alt="">-->
<!--                <p>暂无卡券</p>-->
<!--              </div>-->


<!--              <ul v-if="couponList.length > 1" class="indicators indicator">-->
<!--                <li v-for="(item, index) in couponList" :class=" couponActive == index ? 'active' : ''" :style="{margin:'0 '+ (index ==( couponList.length-1 )? '0px' : ('5px')) +' 0px' +' 0px'}" :key="index" ></li>-->
<!--              </ul>-->
<!--            </div>-->

<!--            <div class="digital-title">-->
<!--              我的徽章-->
<!--            </div>-->

<!--            <div class="digital-info"  v-for="(item,i) in seriseList" :key="i" style="margin-top:15px;">-->
<!--              <div class="digital-top" @click="clickToDigitalListPage">-->
<!--                <p class="digital-info-title">{{item.name}}</p>-->
<!--                <p class="digital-info-num">共{{item.hashList ?item.hashList.length : 0 }}枚</p>-->
<!--                <img src="../../assets/common/icon_right_arrow.png" alt="">-->
<!--              </div>-->

<!--              <div v-if="item.hashList && item.hashList.length>0" class="digital-imgs">-->


<!--                 <img  @click="toProductDetail(node)"  v-for="(node,i) in item.hashList" :key="i" :src="node.shareImage" alt="">-->



<!--              </div>-->

<!--              <div v-else class="no-digital">-->
<!--                <img src="../../assets/nodata/icon_no_digital.png" alt="">-->
<!--                <p>暂无内容</p>-->
<!--              </div>-->


<!--            </div>-->


            <div class="bottom-introduce">
              <div>
                <img class="chain-icon" src="../../assets/icons/chain_logo_big.png">
              </div>
              <div class="chain-botton">提供区块链技术服务支持</div>
            </div>




          </div>

        </div>
      </van-pull-refresh>



    <tabbar></tabbar>

<!--    <van-popup v-model:show="showCouponInfo" :closeable = true>-->
<!--      <div class="couponWarn">-->

<!--        <p class="warn-title">图书券使用细则</p>-->
<!--        <p class="warn-desc">-->
<!--          ①本次活动共发放50元现金图书券1万份，同一用户限领取1张主会场现金图书券、1张分会场现金图书券（包括但不限于同一设备/同一注册手机号/同一银行卡预留手机号/同一身份证号）。<br/>-->
<!--          ②本次活动领取的现金图书券，须在有效时间内前往相应会场现场核销使用，不支持跨会场使用，逾期未使用将自动作废，不予补发。<br/>-->
<!--          ③现金图书券可无门槛同价抵扣，不兑现/不找零/不可转赠/不可售卖。<br/>-->
<!--          ④使用现金图书券的交易如发生退款、撤销等行为，则视为自动放弃优惠，仅退还用户实际支付金额（不包含图书券抵扣金额），且该券视为已使用。<br/>-->
<!--          *如有疑问可致电“我的长沙”APP客服电话进行咨询：0731-85392289（工作日09:00-17:30）-->
<!--        </p>-->

<!--      </div>-->
<!--    </van-popup>-->




  </div>
</template>

<script>
import {ref, reactive, onMounted, toRefs, getCurrentInstance, nextTick} from "vue";
import { useRouter } from "vue-router";;
import { clearToast, createNonceStr, showFailToast, showLoading } from "../../utils/util";
import { SwipeInstance } from 'vant';

import Qrcode from "qrcodejs2";
import router from "../../router";
import {shareInfo} from "../../api/user";
export default {
  name: "mine",
  setup() {
    const $router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      loading:false,
      user:null,
      resultData:null

    })
    const swipeRef = ref();

    const onClickOverlay = () => {
      loadData();
    }



    const onChange = (index) => {
      state.couponActive = index;

    }

    var qrcode;
    onMounted(()=>{

    })



    // const  makeQrcode = () => {
    //
    //   state.couponList.forEach((v, i) => {
    //     nextTick(()=>{
    //
    //         let dom = document.getElementById('qrcode'+ (i))
    //         console.log(dom)
    //         if(dom){
    //           dom.innerText = '';
    //           qrcode = new Qrcode(dom,{
    //             // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
    //             text:  v.couponUrl,
    //             width:dom.offsetWidth - 20,
    //             height:dom.offsetWidth - 20,
    //             colorDark:'#111',//二维码颜色
    //             colorLight:'#fff',//二维码背景颜色
    //             correctLevel:Qrcode.CorrectLevel.L//容错率,L/M/H
    //           })
    //         }
    //
    //
    //       });
    //
    //
    //   })
    //
    //
    //
    //
    // }


    const copyAddress = ((str) => {
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = str;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove()
      proxy.$toast.success('复制成功');
    })

    const clickToDigitalListPage = () => {
      $router.push('/digitalListPage')


    }




    const toMyCoupons = () => {
      $router.push('/myCoupons')
    }


    const toProductDetail = (item) => {

      $router.push({
        path: "/nftDetail",
        query: { productDetail: JSON.stringify(item), isShow: true }
      })
    }

    const toOrder = () => {
      // router.push('/order')
      showFailToast('我的订单功能即将上线')
    }

    const toMyDigitals = () => {
      router.push('/myDigitalListPage')
    }

    const onRefresh = () => {
      loadData();
    }
    const loadData = () => {


      showLoading()
      proxy.$http.get("/wenwu-user/my/index").then(res => {
         state.loading = false;
          state.user = res.data.data.user
          state.resultData = res.data.data
          clearToast()
      })
    }

    const aboutUs = () => {

      proxy.$http.get('/wenwu-commodity/dict/detail?dict_key=aboutStarCity').then(res => {

        if(res.data.code == 200){

          router.push({name:'openBanner',query:{image:res.data.data.dictValue,title:'关于星城玩家'}});

          clearToast()

        }else{
          showFailToast(res.data.message,1000);
        }
      })



    }

    const toMyshare = (() => {


      //埋点上报
      eshimin.bytedanceApplogEvent({
        eventName: 'share_click_button', // 例子
        eventParams: {'share_name': shareInfo.title, 'title_name': '我的页面分享'} ,//例子
        onResponse:  (res)=> {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })

      eshimin.share({
        version: 0,
        title: shareInfo.title,
        content:  shareInfo.desc,
        imgUrl:  shareInfo.logo,
        link: shareInfo.link,
        onResponse: function(res) {
          console.log("share responseData:" + res);
        }
      })

    })

    const toMyMap = () => {

      showFailToast('我的地图功能即将上线')
    }

    const toMyLikes = () => {
      router.push('myLikes')
    }

    loadData();


    return {
      toMyLikes,
      toMyMap,
      toMyshare,
      aboutUs,
      toMyDigitals,
      toOrder,
      onClickOverlay,
      toProductDetail,
      onRefresh,
      swipeRef,
      onChange,
      ...toRefs(state),
      toMyCoupons,
      copyAddress,
      clickToDigitalListPage,


    }
  }
}
</script>

<style scoped lang="scss" src="./index.scss">

</style>


